import { createAction, props } from '@ngrx/store';

import { Auth0Profile, LastSubscription } from 'src/app/appState/app.state';

import { Profile }  from 'src/app/models/profile';


export const initTQapp = createAction(
  '[App] Init TQapp'
);
export const applicationLoading = createAction(
  '[App] Application loading'
);
export const applicationLoaded = createAction(
  '[App] Application loaded'
);

export const sidebarActive = createAction(
  '[App] Sidebar active',
  props<{value: boolean}>()
);
export const sidebarCollapsed = createAction(
  '[App] Sidebar collapsed',
  props<{value: boolean}>()
);

export const onMobile = createAction(
  '[App] Mobile device checked',
  props<{value: boolean}>()
);

export const newAuth0Token = createAction(
  '[App] New Auth0 token',
  props<{token: string}>()
);

export const setBackURL = createAction(
  '[App] Set Last URL',
  props<{ url: string }>()
);

export const enterTQpane = createAction(
  '[App] Enter TQpane',
  props<{pane: string}>()
);

export const activateTQpane = createAction(
  '[App] Next TQpane',
  props<{pane: string}>()
);

export const projectFiltered = createAction(
  '[App] Project Filtered',
  props<{ id: number, code:string, color:string, list:Array<number> }>()
);

export const setAuth0Profile = createAction(
  '[App] Set Auth0Profile',
  props<{ profile: Auth0Profile }>()
);

export const setLastGuardTime = createAction(
  '[App] Set Last GuardTime',
);

export const setTQApiToken = createAction(
  '[App] Set TQApiToken',
  props<{ token: string }>()
);

export const setTQprofileId = createAction(
  '[App] Set TQprofileId',
  props<{ id: number }>()
);

export const setTQprofileEmail = createAction(
  '[App] Set TQprofileEmail',
  props<{ email: string }>()
);

export const setTQprofilePrefs = createAction(
  '[App] Set TQprofilePrefs',
  props<{ profile: Profile }>()
);

export const setTQroleSelectedId = createAction(
  '[App] Set TQroleSelectedId',
  props<{ id: number }>()
);

export const setTQroles = createAction(
  '[App] Set TQroles',
  props<{ roles: Array<any> }>()    // TODO Array<Role>
);

export const setTQproductPromotions = createAction(
  '[App] Set TQproductPromotions',
  props<{ promos: string }>()  
);

export const setLastSubscription = createAction(
  '[App] Set Last Subscription',
  props<{ subscription: LastSubscription }>()
);

