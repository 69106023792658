import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { TreeNode } from 'primeng/api';
import { TreeSelectModule } from 'primeng/treeselect';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { Store } from '@ngrx/store';
import { AppState, CONSTANTS } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import { ProjectsState } from 'src/app/projects/store/projects.state';
import { projectsState } from 'src/app/projects/store/projects.selectors';
import * as PROJECTS_ACTIONS from 'src/app/projects/store/projects.actions';

import { TQRoleSelectorComponent } from 'src/app/shared/widgets/tq-role-selector/tq-role-selector.component';

import { DateTime } from 'luxon';

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { TQDateTimeService } from 'src/app/services/tqdatetime.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy
{
  faQuestionCircle = faQuestionCircle;
  
  appState: AppState;
  appStateSubs: any;

  projectsState: ProjectsState;
  projectsStateSubs: any;
  canAddProject: boolean = false;

  projectId: number = 0; // Copy of projectsState.parentProjectId
  projectTitle: string = "";
  projectCode: string = "";
  projectParentId: number = null;
  projectRoleId: number;
  projectRoleCode: string = CONSTANTS.ROLE.ALL_code
  projectDescription: string = "";
  projectColor: string = "white";
  projectOrigin: string = null;

  projectsNodes: TreeNode[];
  parentProjectNode: TreeNode;

  activities: any;
  activitiesNumber: number = 0;
  activitiesTotalDuration: number = 0;

  activityReportStartDate: DateTime = null;
  activityReportEndDate: DateTime = null;
  
  selectedTab = 0;
  clicked = false;


  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private store: Store, 
    public  samApp: SamuraiService,
    private tqApi: TQApiService,
    public  tqDT: TQDateTimeService,
    private tqSession: TQSessionService,
  )
  {}

  async ngOnInit()
  {
    this.appStateSubs = this.store.select(appState)
      .subscribe( state => { 
        this.appState = state
        if (state.status != 'loaded') return;

        this.canAddProject = this.tqSession.canAddProject()
      })

    this.projectsStateSubs = this.store.select(projectsState)
      .subscribe( state => {
        this.projectsState = state 
        if (state.status != 'loaded') return;

        // Get the project in the tree
        this.projectsNodes = [...this.buildProjectSelector()]
        this.parentProjectNode=this.findProjectNode(this.projectsNodes, this.appState.projectFilterId)
   
        this.canAddProject = this.tqSession.canAddProject()
      })

    this.projectId = Number.parseInt(this.activeRoute.snapshot.paramMap.get('id'))
    if ( this.projectId == 0 )
    {
      if (this.appState.projectFilterId == 0)
      {
        // Top level: Set default project as parent project
        this.projectParentId = this.appState.default_project_id; 
        this.projectRoleId = null;
      }
      else
      {
        this.projectParentId = this.projectsState.TQprojects.find(p => p.id == this.appState.projectFilterId).id
        this.projectRoleId = this.projectsState.TQprojects.find(p => p.id == this.projectParentId).role_id  
      }
      this.parentProjectNode=this.findProjectNode(this.projectsNodes, this.projectParentId)
    }
    else
    {
      this.getProject(this.projectId)
    }
    this.projectRoleCode = this.tqSession.getTQRole(this.projectRoleId).code

    this.canAddProject = this.tqSession.canAddProject()

    // Check for a tab query parameter
    let tab = this.activeRoute.snapshot.queryParams['tab']
    switch (tab)
    {
      case "description": this.selectedTab = 0; break;
      case "activity":    this.selectedTab = 1; break;
    } 
        
    // Focus the first field at top
    let elem = document.getElementById("ProjectCode");
    setTimeout(() =>
      {
        elem.focus();
        elem.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
      }, 100);
  }

  ngOnDestroy()
  {
    this.appStateSubs.unsubscribe();
    this.projectsStateSubs.unsubscribe();
  }

  @HostListener('document:keydown.control.s', ['$event'])
  CtrlS(event: any)
  {
    event.preventDefault();
    document.getElementById("ProjectSaveButton").click()
  }
  @HostListener('document:keydown.control.d', ['$event'])
  CtrlD(event: any)
  {
    event.preventDefault();
    document.getElementById("ProjectDuplicateButton").click()
  }
  @HostListener('document:keydown.escape', ['$event'])
  Escape(event: any)
  {
    event.preventDefault();
    document.getElementById("ProjectCancelButton").click()
  }

  async getProject(id: number)
  {
    // Ignore new projects
    if (id == 0) return;

    try
    {
      var res = await this.tqApi.getProject(id)
    }
    catch (apiError)
    {
      this.router.navigate(["/loading"])
    }

    this.projectId = res["id"]
    this.projectCode = res["code"]
    this.projectTitle = res["title"]
    this.projectParentId = res["parent_project_id"] || 0 // Zero needed by the tq-selector
    this.projectRoleId = res["role_id"]                  // Undefined when null in DB
    this.projectDescription = res["description"]
    this.projectColor = res["color"]
    this.projectOrigin = res["origin"]

    // Load the role code
    this.projectRoleCode = this.tqSession.getTQRole(this.projectRoleId).code

    // Select parent project
    this.parentProjectNode=this.findProjectNode(this.projectsNodes, this.projectParentId)

    // Get project activity
    this.getProjectActivity(this.projectId)
  }

  async addProject()
  {
    // Return when parent project unselected
    if (this.parentProjectNode == null)
    {
      this.clicked = false;
      return;
    }
    // Return when empty titles or empty codes
    if (this.projectTitle == "" || this.projectCode == "" )
    {
      this.clicked = false;
      return;
    }

    var TQProject = {
        "id"                : this.projectId,
        "profile_id"        : this.appState.TQprofileId,
        "parent_project_id" : this.parentProjectNode.data,
        "role_id"           : this.projectRoleId || "",
        "title"             : this.projectTitle,
        "code"              : this.projectCode,
        "description"       : this.projectDescription || "",
        "color"             : this.projectColor,
        //"origin"            : this.projectOrigin || null
      }

    let res = await this.tqApi.postProject(TQProject)

    // Prepare Projectpad status for a new project
    this.store.dispatch(PROJECTS_ACTIONS.selectProject({id: res['id']}))
    this.store.dispatch(PROJECTS_ACTIONS.loadProjectsList())

    this.router.navigate(['projectpad'])
  }

  async duplicateProject()
  {
    var TQProject = {
        "id"                : 0,
        "profile_id"        : this.appState.TQprofileId,
        "parent_project_id" : (this.parentProjectNode) ? this.parentProjectNode.data : null,
        "role_id"           : this.projectRoleId || "",
        "title"             : (this.projectTitle + " (duplicated)").slice(0,254),
        "code"              : this.projectCode,
        "description"       : this.projectDescription || "",
        "color"             : this.projectColor,
        //"origin"            : this.projectOrigin || null
      }
    let res = await this.tqApi.postProject(TQProject)

    // Prepare Projectpad status for a new project
    this.store.dispatch(PROJECTS_ACTIONS.selectProject({id: res['id']}))

    this.router.navigate([this.appState.lastPane])
  }

  cancelProject()
  {
    this.router.navigate([this.appState.lastPane])
  }

  async deleteProject()
  {
    await this.tqApi.deleteProject(this.projectId)

    this.store.dispatch(PROJECTS_ACTIONS.selectProject({id: 0}))

    // Remove project filter if this project was the selected project
    if (this.projectId == this.appState.projectFilterId)
    {
      this.store.dispatch(APP_ACTIONS.projectFiltered({ id: 0, code:'', color:'', list:[]}))
    }

    this.router.navigate([this.appState.lastPane])
  }

  findProjectNode(tree:TreeNode[], id:number): TreeNode
  {
    for (let i in tree)
    {
      let p = tree[i]
      if (p.data == id) return p       
      if (p.children.length != 0 ) {
        let node = this.findProjectNode(p.children, id)
        if (node) return node
      }
    }
  }

  // TODO Make tq-project-selector a component and add to Notepad
  buildProjectSelector(): TreeNode[]
  {
    function getNodes(projectList, levelProjects: Array<any>, level: number, ignoreProject: number)
    {
      const nodes = [];
      levelProjects.forEach( p => 
      {
        if (p.id != 0 && p.id == ignoreProject) return;

        let children = projectList.filter(ch => ch.parent_project_id == p.id)
        nodes.push(
          {
            data: p.id,
            label: p.code,
            children: (children.length != 0) 
                        ? getNodes(projectList, 
                                   children, 
                                   level+1, 
                                   ignoreProject) 
                        : [],
            //expandedIcon: "pi pi-folder",
            //collapsedIcon: "pi pi-folder",
            //selectable: p.id != ignoreProject,
          }
        )
      })
      return nodes
    }

    let tree:TreeNode[] = []

    // Add an empty node to select empty parent project 
    let emptyNode = {
      id: 0,
      code: "<NONE>",
      title: "",
      parent_project_id: null
    }
    let projectList = [emptyNode, ...this.projectsState.TQprojects] 

    // Start with root projects as level 0, and get nodes except this same project and its children
    tree = getNodes(projectList, projectList.filter(p => p.parent_project_id == null), 0, this.projectsState.selectedProjectId)
    return tree
  }

  setProjectColor(color: string)
  {
    this.projectColor = color;
  }

  getProjectColor(): string
  {
    return this.projectColor;
  }

  isDefaultProject()
  {
    return this.projectId == this.appState.default_project_id;  /// tqSession.getSession().default_project_id
  }

  isLeafProject()
  { 
    return (this.projectsState.TQprojects.filter(p => p.parent_project_id == this.projectId).length == 0)
  }

  confirmDelete() 
  {
    this.confirmationService.confirm({
      accept: () => { 
        this.deleteProject()
      },
      reject: (type:ConfirmEventType) => {
        switch(type) {
          case ConfirmEventType.REJECT:
            this.clicked = false
          break;
          case ConfirmEventType.CANCEL:
            this.clicked = false
          break;
        }
      }
    });
  }

  parentChanged(event)
  {
    let newParentProject = this.projectsState.TQprojects.find(p => p.id == event.node.data)
    if (newParentProject == null) return;
    if (newParentProject.id == this.projectId) return;

    if (newParentProject == null) 
    {
      this.projectParentId = 0
      this.projectRoleId = null
      this.projectRoleCode = ''
    }
    else
    {
      this.projectParentId = newParentProject.id
      let newRole = this.tqSession.getTQRole(newParentProject.role_id)
      if (newRole == null) 
      {
        this.projectRoleId = null
        this.projectRoleCode = ''
      }
      else
      {
        this.projectRoleId = newRole.id  
        this.projectRoleCode = newRole.code  
      }
    }
  }

  async getProjectActivity(projectId: number)
  {
    this.activitiesNumber = 0
    this.activitiesTotalDuration = 0
    this.activities = []
    if (this.samApp.getFeatures().allowActivityLog == false) return;

    try
    {
      var res: any = await this.tqApi.getActivitySummaryByProjectId(projectId)
    }
    catch (apiError)
    {
      this.router.navigate(["/loading"])
    }
    if (res == null) return;

    // Load activities
    this.activities = []
    for (let i=0; i<res.length; i++)
    {
      let date = this.tqDT.formatDate(this.tqDT.toLuxonFromISO(res[i][0]))
      let from = this.tqDT.formatDate(this.activityReportStartDate)
      let to = this.tqDT.formatDate(this.activityReportEndDate)

      if (this.activityReportStartDate != null && this.tqDT.isDateBefore(date, from)) continue;
      if (this.activityReportEndDate != null   && this.tqDT.isDateAfter(date, to)) continue;

      this.activities.push(
        {
          startDate:   res[i][0],
          numAct:      res[i][1],
          sumValue:    res[i][2],
          description: res[i][3],
        })
    }

    // Get activity summary
    this.activitiesNumber = this.activities.reduce((acc, act) => acc + act.numAct, 0)
    this.activitiesTotalDuration = this.activities.reduce((acc, act) => acc + act.sumValue, 0)
  }

  editProjectActivity()
  {
    // Leaving component
    this.store.dispatch(APP_ACTIONS.activateTQpane({ pane:'activity' })) 

    this.store.dispatch(APP_ACTIONS.setBackURL({ url:'/project/'+this.projectId+'?tab=activity' })) 
    this.router.navigate(["/activity"], { queryParams: { project: this.projectId } });
  }


  clearActivityReportStartDate()
  {
    this.activityReportStartDate = null;
    this.getProjectActivity(this.projectId);
  }

  clearActivityReportEndDate()
  {
    this.activityReportEndDate = null;
    this.getProjectActivity(this.projectId);
  }

  updateActivityReportStartDate()
  {
    console.log("Update Start Date")  
    console.log("Start Date: ", this.activityReportStartDate)
    this.getProjectActivity(this.projectId);
  }

  updateActivityReportEndDate()
  {
    console.log("Update End Date")  
    console.log("End Date: ", this.activityReportEndDate)
    this.getProjectActivity(this.projectId);
  }

  async reportActivityPDF()
  {
    const PDF:Blob = await this.tqApi.getActivityPDFReportByProjectId(
      this.projectId, 
      this.tqDT.formatDateISO(this.activityReportStartDate), 
      this.tqDT.formatDateISO(this.activityReportEndDate)
    );

    const URL = window.URL.createObjectURL(PDF);

    // Create a temporary <a> element
    const a = document.createElement('a');
    a.href = URL;
    a.target = '_blank';
    a.rel = 'noopener noreferrer'; 
    //a.download = 'ProjectActivity.pdf'; 
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoke after a short delay
    setTimeout(() => window.URL.revokeObjectURL(URL), 5*60*1000);

    this.clicked = false;
  }
  
}
