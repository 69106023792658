<div class="samPane">
  <div class="samPaneHeader">
    <h4 class="samPaneTitle">
      <span class="mr-1" *ngIf="taskId ==0">Project Activity</span>
      <span class="mr-1" *ngIf="taskId !=0">Task Activity</span>
      <a href="https://docs.taskquark.com/activity.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h4>
  </div>

  <!-- <div *ngIf="!canAddActivity"
    class="alert alert-danger" role="alert">
    You have reached the maximum number of tasks for your subscription plan.
  </div>

  <div *ngIf="!canEditactivity"
    class="alert alert-danger" role="alert">
    You do not have permissions to modify this task.
  </div> -->

  <div class="ml-2 mr-2">

    <!-- ACTIVITY HEADER  -->

    <label for="ProjectCode" 
      class="TQ-checkbox-label mt-3 ml-2 mr-2">
      Project:
    </label>
    <input id="ProjectCode"
      type="Text" 
      [disabled]="true"
      class="ml-2 mt-1 mb-1 pl-1 pr-1" 
      [ngClass]="{'col-sm-3' : this.samApp.onMobile, 'col-2' : !this.samApp.onMobile}" 
      [(ngModel)]="projectCode">
    <input id="ProjectTitle"
      type="Text" 
      [disabled]="true"
      class="ml-2 mt-1 mb-1 pl-1 pr-1" 
      [ngClass]="{'col-sm-9' : this.samApp.onMobile, 'col-8' : !this.samApp.onMobile}" 
      [(ngModel)]="projectTitle">

    <div *ngIf="taskId != 0">
      <label for="Tasktitle" 
      class="TQ-checkbox-label mt-3 ml-2 mr-2">
      Task:
    </label>
    <input id="TaskTitle"
      type="Text" 
      [disabled]="true"
      class="ml-2 mt-1 mb-1 pl-1 pr-1" 
      [ngClass]="{'col-sm-12' : this.samApp.onMobile, 'col-11' : !this.samApp.onMobile}" 
      [(ngModel)]="taskTitle">
    </div>

    <!-- ACTIVITY TAB -->

    <mat-tab-group 
      class="mt-2"
      [mat-stretch-tabs]=false
      [(selectedIndex)]="selectedTab">

      <mat-tab label="Activities">
        <div class="m-1">
          <div style="display:inline-block">
            <label for="ActivityStartDatePicker" 
              class="TQ-checkbox-label mt-3 ml-2 mr-2">
              Date:
            </label>
            <tq-date-widget #ActivityStartDatePicker
              [maxDate]="activityEndDate"
              [(date)]="activityStartDate"
            ></tq-date-widget>  
            <button 
              class="badge TQ-clear-button ml-1" 
              (click)="clearActivityStartDate()">
              X
            </button>
          </div>
          <div *ngIf="activityStartDate" 
            style="display:inline-block">
            <tq-time-widget class="ml-3"  
              [(time)]="activityStartTime"
              [timeFormat]=this.appState.prefLocTimeFormat
            ></tq-time-widget>
            <button 
              class="badge TQ-clear-button ml-1" 
              (click)="clearActivityStartTime()">
              X
            </button>
          </div>
      
          <br>
      
          <label 
            class="TQ-checkbox-label mt-3 ml-2 mr-1">
            Duration:
          </label>
          <tq-duration-widget 
            [(duration)]="activityDuration"
          ></tq-duration-widget>
          <button class="badge TQ-clear-button ml-1" 
            (click)="clearActivityDuration()">
            X
          </button>
      
          <label for="activityDescription" 
            class="TQ-checkbox-label mt-3 ml-4 mr-1">
            Description:
          </label>
          <input id="activityDescription" 
            type="text" 
            class="ml-1 mt-1 mb-1 pl-1 pr-1" 
            [ngClass]="{'col-sm-12' : this.samApp.onMobile, 'col-8' : !this.samApp.onMobile}" 
            [(ngModel)]="activityDescription"
          >
      
          <hr>
          <button id="ActivitySaveButton"
            title="Add Activity" 
            class="btn btn-success ml-2" 
            [disabled]="!canAddActivity || this.activityStartDate == null || (this.activityDuration == null && !this.activityDescription)" 
            (click)="editing=false; addActivity()"
          >
            {{ editing ? "Save" : "Add" }}
          </button>
          <button id="ActivityCancelButton"
            title="Cancel Activity" 
            class="btn btn-warning ml-2" 
            (click)="editing=false; cancelActivity()"
          >
            Cancel
          </button>
          <button id="ActivityDuplicateButton"
            *ngIf="editing" 
            title="Duplicate Activity" 
            class="btn btn-success ml-3" 
            [disabled]="!canAddActivity || this.activityStartDate == null || (this.activityDuration == null && !this.activityDescription)"  
            (click)="editing=false; duplicateActivity()"
          >
            Duplicate
          </button>
          <button id="ActivityDeleteButton"
            *ngIf="editing" 
            title="Delete Activity" 
            class="btn btn-danger ml-5" 
            style="float:right"
            [disabled]="!canEditActivity" 
            (click)="editing=false; confirmDeleteActivity()"
          >
            Delete
          </button>
        </div>

        <!-- ACTIVITY TABLE  -->

        <mat-card class="mt-3 mb-5 m-1"> <!-- Leave bottom space for the paginator dropdown -->
          <mat-card-content>
            <div class="row ml-2">
              <div class="ml-1"><b>Number of entries: </b>{{activities_number}}</div>
              <div class="ml-3"><b>Total duration: </b>{{tqDT.minutesToDuration(activities_time)}}</div>
            </div>

            <p-table 
              [value]="activities" 
              rowGroupMode="rowspan" groupRowsBy="startDate"
              styleClass="p-datatable-gridlines"
              [paginator]="true"
              [rows]="25"
              [rowsPerPageOptions]="[25, 50]"
              [responsiveLayout]="'scroll'"
              [scrollable]="false"  
            >
              <ng-template pTemplate="header">
                <tr>
                  <th class="p-2" style="width: 110px; text-align:center;"
                    pSortableColumn="startDate">
                    Date
                    <p-sortIcon field="startDate"></p-sortIcon>
                  </th>
                  <th class="p-2" style="width: 85px"></th>
                  <th class="p-2" style="width: 75px">Duration</th>
                  <!-- 
                  <th class="p-2" style="width: 4%">End Date</th>
                  <th class="p-2" style="width: 4%"></th> 
                  -->
                  <th class="p-2" >Description</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-act let-rowgroup="rowgroup" let-rowspan="rowspan">
                <tr 
                  [class.table-success]="(act.id==this.activityId)"
                  (click)="editActivity(act.id)"          
                >
                  <td class="p-1" style="text-align:center; vertical-align:top;" 
                    *ngIf="rowgroup" [attr.rowspan]="rowspan">
                    {{ tqDT.dateToTZ(act.startDate, act.startTime) }} 
                  </td>
                  <td class="p-1" style="text-align:center">
                    {{ tqDT.formatTimeFromISO(act.startDate, tqDT.timeToTZ(act.startDate, act.startTime)) }}
                  </td>
                  <td class="p-1" style="text-align:right">
                    <span *ngIf="act.value && act.value !== 0">{{ tqDT.minutesToDuration(act.value) }}</span>
                  </td>
                  <!-- <td class="p-1">
                    {{ tqDT.dateToTZ(act.endDate, act.endTime) }} 
                  </td>
                  <td class="p-1">
                    {{ tqDT.formatTimeFromISO(act.endDate, tqDT.timeToTZ(act.endDate, act.endTime)) }}
                  </td> -->
                  <td class="p-1">
                    {{ act.description }}
                  </td>
                </tr>
              </ng-template>
            </p-table>

          </mat-card-content>
        </mat-card>

      </mat-tab>
    </mat-tab-group>

  </div>

  <p-confirmDialog #cd
    key="deleteActivity"
    [style]="{width: '33vw'}" 
    [breakpoints]="{'960px': '75vw', '640px': '95vw'}"
    [baseZIndex]="10000"
    focusTrap="true"
    closeOnEscape="true">
    <ng-template pTemplate="header">
      <h3>Delete Activity</h3>
    </ng-template>
    <ng-template pTemplate="message" let-message>
      <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          Are you sure you want to delete this activity?
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-warning ml-2" (click)="cd.reject()">Cancel</button>
        <button type="button" class="btn btn-danger ml-2" (click)="cd.accept()">Delete</button>
    </ng-template>
  </p-confirmDialog>

  <div *ngIf="this.samApp.onMobile">
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>

</div>
