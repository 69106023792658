import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import * as APP_ACTIONS from 'src/app/appState/app.actions';

import { Auth0Service } from 'src/app/services/auth0/auth0.service';
import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrl: './signin.component.scss'
})
export class SigninComponent implements OnInit
{
  faQuestionCircle = faQuestionCircle;
  faSpinner = faSpinner;

  constructor
  (
    private auth0: Auth0Service,
    private samApp: SamuraiService,
    private store: Store,
    private tqApi: TQApiService,
  )
  {}

  ngOnInit()
  {
    // this.store.dispatch(APP_ACTIONS.enterTQpane( {pane:'signin'} ))

    this.samApp.trace("SIGNIN...")
    this.tqApi.pingSIGNIN();
   
    this.auth0.signin('/loading');
  }

}
