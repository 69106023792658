import { environment } from 'src/environments/environment';

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output  } from '@angular/core';

import { MenuItem } from 'primeng/api';

@Component({
  selector: 'tq-project-menu',
  templateUrl: './tq-project-menu.component.html',
  styleUrl: './tq-project-menu.component.scss'
})
export class TQProjectMenuComponent implements AfterViewInit
{
  @Input() target: ElementRef; 
  @Input() canAddProject: boolean;
  @Output() command = new EventEmitter<string>();

  menuItems: MenuItem[];
  
  constructor
  (
    private changeDetectorRef: ChangeDetectorRef,
  ) 
  {}


  ngAfterViewInit()
  {
    this.menuItems =
    [
      {
        label:'Add subproject',
        icon:'pi pi-fw pi-plus',
        command: (event) => this.outputCommand(event)
      },
      {
        separator: true,
      },
      {
        label:'Select',
        icon:'pi pi-fw pi-sun',
        command: (event) => this.outputCommand(event)
      },
      {
        label:'Edit',
        icon:'pi pi-fw pi-pencil',
        command: (event) => this.outputCommand(event)
      },  
      {
        separator: true,
        visible: environment.samurai.features.allowActivityLog
      },
      {
        label:'Log Activity',
        icon:'pi pi-fw pi-clock',
        command: (event) => this.outputCommand(event),
        visible: environment.samurai.features.allowActivityLog
      },
      // {
      //   label:'Duplicate',
      //   icon:'pi pi-fw pi-copy',
      //   command: (event) => this.outputCommand(event),
      //   disabled: this.canAddProject == false
      // },
      // {
      //   separator: true,
      // },
      // {
      //   label:'Delete',
      //   icon:'pi pi-fw pi-trash',
      //   command: (event) => this.outputCommand(event)
      // }
    ]
      
    this.changeDetectorRef.detectChanges();
  } 

  
  outputCommand (event: any)
  {
    this.command.emit(event.item.label); 
  }

}
