import { Component, HostListener, OnDestroy, OnInit} from '@angular/core'
import { Router, ActivatedRoute, NavigationEnd  } from "@angular/router"
import { HttpClient, HttpHeaders} from '@angular/common/http'

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import * as PROJECTS_ACTIONS from 'src/app/projects/store/projects.actions';
import * as TASKS_ACTIONS from 'src/app/tasks/store/tasks.actions';
import * as NOTES_ACTIONS from 'src/app/notes/store/notes.actions';

import { SamuraiService} from 'src/services/samurai/samurai.service';
import { Auth0Service } from 'src/app/services/auth0/auth0.service';
import { TQApiService} from 'src/app/services/tqapi.service'
import { TQSessionService } from 'src/app/services/tqsession.service';

import { timer } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { DateTime, Duration } from 'luxon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{
  appState: AppState;
  appStateSubs: any;
 
  constructor
  (
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private auth0: Auth0Service,
    private samApp: SamuraiService,
    private tqApi: TQApiService,
    private tqSession: TQSessionService,
  ) 
  {
    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state 
      })

    // Subscribe to router events to track URL changes 
    // and help detecting a page reload
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let url = event.urlAfterRedirects
        const excludedUrls = [
            "/signin",
            "/signup",
            "/loading",
            "/logout",
            "/callback"];
        if (!excludedUrls.some(excluded => url.startsWith(excluded))) 
        {
          this.samApp.debug("APP lastRoute set to " + url)
          this.samApp.saveStore("TQlastURL", event.urlAfterRedirects, "session");
        }
      })
  }

  async ngOnInit()
  {
    this.samApp.trace("APP loading -> " + this.router.url)
    this.tqApi.trace("APP loading -> " + this.router.url)

    this.store.dispatch(APP_ACTIONS.applicationLoading())
    
    this.store.dispatch(APP_ACTIONS.initTQapp());
    
    this.store.dispatch(APP_ACTIONS.onMobile( {value:this.samApp.onMobile} ))
  
    // Update Auth0 Access Token
    await this.updateAuth0Token()
        
    // Check versions and connectivity 
    timer(0, 60*60*1000).subscribe( async () => {
      await this.checkAppVersion()
      await this.tqApi.pingTQAPI()
      await this.tqApi.pingTQDB()
    })

    // Logout after some time with no ProfileGuard checked
    timer(0, 1*1000).subscribe( () =>
      {
        if (this.appState.status != 'loaded') return;
        
        let maxInactiveTime = Duration.fromObject({minutes: parseInt(this.appState.prefLogoutAfterInactiveFor)})
        if (maxInactiveTime.toMillis() > 0) 
        {
          let inactiveTime = DateTime.now().diff(this.appState.lastGuardTime, 'minutes')
          if (inactiveTime > maxInactiveTime)
          {
            this.router.navigate(['/logout'])
          }  
        }
      });
      
    // Recover TQsession from localStorage
    this.samApp.debug("APP recovering TQsession...")
    let TQsession = this.samApp.retrieveStore("TQsession")
    if (TQsession)
      {
      this.samApp.trace("APP loaded TQsession: " + TQsession.id)
      
      this.store.dispatch(APP_ACTIONS.setTQApiToken({token: TQsession.tqapi_token}))
      
      this.store.dispatch(APP_ACTIONS.setTQprofileId({id: TQsession.id}))
      this.store.dispatch(APP_ACTIONS.setTQprofileEmail({email: TQsession.email}))
      this.store.dispatch(APP_ACTIONS.setTQroleSelectedId({id: TQsession.TQroleSelectedId}))
      this.store.dispatch(APP_ACTIONS.setTQprofilePrefs({profile: TQsession}))
    }
    else
    {
      this.samApp.trace("APP loaded TQsession was empty!")
    }

    this.store.dispatch(APP_ACTIONS.applicationLoaded())
    this.samApp.debug("APP loaded.")
    this.tqApi.debug("APP loaded.")

  }

  ngOnDestroy()
  {
    this.appStateSubs.unsubscribe();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void 
  {
    // Check if still in the same profile as per localStorage
    if (this.samApp.retrieveStore("TQsession") == null ||
        this.samApp.retrieveStore("TQsession").id != this.appState.TQprofileId)
    {
      this.samApp.trace("APP focus: TQsession or Auth0Profile changed -> signin")
      this.tqApi.trace("APP focus: TQsession or Auth0Profile changed -> signin")
      this.router.navigate(['/signin'])        
    }

    if (this.auth0.isAuthenticated && this.samApp.getFeatures().reloadOnTab)
    {
      this.samApp.trace("APP TAB focus ---")
      this.tqApi.trace("APP TAB focus ---")
      this.store.dispatch(PROJECTS_ACTIONS.loadProjectsList()) // Update the sidebar
      this.store.dispatch(TASKS_ACTIONS.loadTasksList())       // Update the tasks list    
      // this.store.dispatch(NOTES_ACTIONS.loadNotesList())       // Update the notes list  // Save calls for now
    }
  }

  onSwipeRight($event)
  {
    // Ignore swipe on HOME
    if (this.activatedRoute['_routerState'].snapshot.url == "/") return;

    this.store.dispatch(APP_ACTIONS.sidebarActive({value:true}))
  }
  onSwipeLeft($event)
  {
    // Ignore swipe on HOME
    if (this.activatedRoute['_routerState'].snapshot.url == "/") return;

    this.store.dispatch(APP_ACTIONS.sidebarActive({value:false}))
  }


  async checkAppVersion()
  {
    try
    {
      this.tqApi.setTQWEB_led("yellow");
      
      // Get last application.json 
      var headers = new HttpHeaders({
        'Content-type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      });
      var res = await this.http.get("/assets/sam/application.json", { headers }).toPromise()

      // Check version on server
      if (res["application"]["version"] == this.samApp.getVersion())
      {
        this.tqApi.setTQWEB_led("green");
      }
      else
      {
      this.tqApi.setTQWEB_led("yellow");
      }
    }
    catch
    {
      this.tqApi.setTQWEB_led("red");
    }
  }

 
  /*** Auth0 MANAGEMENT ***/

  async updateAuth0Token()
  {
    this.samApp.debug("APP Auth0Token$: Update getTokenSilently$")
    this.tqApi.debug("APP Auth0Token$: Update getTokenSilently$")
   
    // Save Auth0 Access Token as TQAPI bearer 
    let token = await this.auth0.getTokenSilently()
    this.store.dispatch(APP_ACTIONS.newAuth0Token({ token: ""+token }))

    // Save Auth0 User as Auth0profile 
    let auth0Profile = await this.auth0.getUser()
    this.store.dispatch(APP_ACTIONS.setAuth0Profile({ 
      profile: { 
        email: auth0Profile.email, 
        email_verified: auth0Profile.email_verified, 
        sub: auth0Profile.sub,
      }})) 

    // Get TQprofile
    this.samApp.trace("APP Auth0Token$: posting profile...")
    this.tqApi.trace("APP Auth0Token$: posting profile...")
    await this.tqSession.postTQProfile()

    // Load the rest of session 
    this.samApp.trace("APP Auth0Token$: getting profile...")
    this.tqApi.trace("APP Auth0Token$: getting profile...")
    await this.tqSession.getTQProfile() 
    await this.tqSession.getRolesByProfile();    
    await this.tqSession.getSubscriptionsByProfile();
    
    // Update lists for the sidebar and page reloads
    this.store.dispatch(PROJECTS_ACTIONS.loadProjectsList())
    this.store.dispatch(TASKS_ACTIONS.loadTasksList())
    this.store.dispatch(NOTES_ACTIONS.loadNotesList())

    // Route after receiving a new token
    this.samApp.trace("APP Auth0Token$: trying to route to URL "+ this.router.url)
    this.tqApi.trace("APP Auth0Token$: trying to route to URL "+ this.router.url)

    // Route to the startPad, if comes from /loading or /
    if (this.router.url == "/" || this.router.url == "/loading" || this.router.url == "/signin" || this.router.url == "/signup")
    {
      this.store.dispatch(APP_ACTIONS.onMobile( {value:this.samApp.onMobile} ))

      let startURL = this.appState.prefLoginStartPad;
      if (!startURL || startURL == "/") 
      {
        this.samApp.trace("APP Auth0Token$: startPad was " +  startURL+ " -> set to workpad")
        startURL = "workpad";
      }
      
      let lastRoute = this.samApp.retrieveStore("TQlastURL", "session")
      if (lastRoute && lastRoute != "/") 
      {
        startURL = lastRoute
      }

      this.samApp.trace("APP Auth0Token$: final route 1 to startURL " +  startURL )
      this.tqApi.trace("APP Auth0Token$: final route 1 to startURL " +  startURL )
      this.router.navigate([startURL])
    }
    else
    {
      this.samApp.trace("APP Auth0Token$: final route 2 to URL " +  this.router.url )
      this.tqApi.trace("APP Auth0Token$: final route 2 to URL " +  this.router.url )
      this.router.navigateByUrl(this.router.url)
    }
  }

}
