import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { Store } from '@ngrx/store';
import { AppState, CONSTANTS } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import { ProjectsState } from 'src/app/projects/store/projects.state';
import { projectsState } from 'src/app/projects/store/projects.selectors';
import * as PROJECTS_ACTIONS from 'src/app/projects/store/projects.actions';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQDateTimeService } from 'src/app/services/tqdatetime.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { faCalendarCheck, faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faAngleDoubleLeft, faAngleDoubleRight, faArrowLeft, faArrowRight, faCalendarWeek, faCalendarDay,
         faCaretSquareRight, faMinusSquare, faPlusSquare, faSquare } from '@fortawesome/free-solid-svg-icons';


interface ProjectNode
{
  id: number;
  code: string;
  title: string;
  children: ProjectNode[];
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy
{
  faAngleDoubleLeft = faAngleDoubleLeft;
  faAngleDoubleRight = faAngleDoubleRight;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faCalendar = faCalendar;
  faCalendarCheck = faCalendarCheck;
  faCalendarDay = faCalendarDay;
  faCalendarWeek = faCalendarWeek;
  faCaretSquareRight = faCaretSquareRight;
  faMinusSquare = faMinusSquare;
  faPlusSquare = faPlusSquare;
  faSquare = faSquare;

  environment = this.samApp.getEnvironment();

  appState: AppState;
  appStateSubs: any;

  projectsState: ProjectsState;
  projectsStateSubs: any;

  TQsession: any;
  TQroles: any[];
  TQroleSelectedCode: string;

  projectsTree = []

  treeSource = new MatTreeNestedDataSource<ProjectNode>();
  treeControl = new NestedTreeControl<ProjectNode>(node => node.children);
  hasChild = ( _: number, node: ProjectNode ) => !!node.children && node.children.length > 0;

  constructor
  (
    private router: Router,
    private store: Store, 
    public  samApp: SamuraiService,
    public  tqDT: TQDateTimeService,
    public  tqSession: TQSessionService
  ) {}

  ngOnInit(): void
  {
    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state
        if (state.status != 'loaded') return;

        this.TQroles = this.appState.TQroles;
        if (!this.appState.TQroleSelectedId)
        {
          this.TQroleSelectedCode = CONSTANTS.ROLE.ALL_code
        }
        else
        {
          this.TQroleSelectedCode = this.TQroles.find(r => r.id == this.appState.TQroleSelectedId)?.code
        }
      })

    this.projectsStateSubs = this.store.select(projectsState)
      .subscribe( state => {
        this.projectsState = state
        if (state.status != 'loaded') return

        this.createProjectsTree(this.projectsState.TQprojects)
      })

    this.createProjectsTree(this.projectsState.TQprojects)
  }

  ngOnDestroy(): void 
  {
    this.appStateSubs.unsubscribe()
    this.projectsStateSubs.unsubscribe()
  }

  @HostListener('document:keydown.alt.1', ['$event'])
  AltTodopad(event: any)
  {
    event.preventDefault();
    this.store.dispatch(APP_ACTIONS.activateTQpane({ pane:'todopad' })) 
    this.router.navigate(['/todopad']);
  }
  @HostListener('document:keydown.alt.2', ['$event'])
  AltWeekpad(event: any)
  {
    event.preventDefault();
    this.store.dispatch(APP_ACTIONS.activateTQpane({ pane:'weekpad' })) 
    this.router.navigate(['/weekpad']);
  }
  @HostListener('document:keydown.alt.3', ['$event'])
  AltMonthpad(event: any)
  {
    event.preventDefault();
    this.store.dispatch(APP_ACTIONS.activateTQpane({ pane:'monthpad' })) 
    this.router.navigate(['/monthpad']);
  }
  @HostListener('document:keydown.alt.4', ['$event'])
  AltWorkpad(event: any)
  {
    event.preventDefault();
    this.store.dispatch(APP_ACTIONS.activateTQpane({ pane:'workpad' })) 
    this.router.navigate(['/workpad']);
  }
  @HostListener('document:keydown.alt.5', ['$event'])
  AltNotepad(event: any)
  {
    event.preventDefault();
    this.store.dispatch(APP_ACTIONS.activateTQpane({ pane:'notepad' }))
    this.router.navigate(['/notepad']);
  }
  @HostListener('document:keydown.alt.6', ['$event'])
  AltProjectpad(event: any)
  {
    event.preventDefault();
    this.store.dispatch(APP_ACTIONS.activateTQpane({ pane:'projectpad' }))
    this.router.navigate(['/projectpad']);
  }


  createProjectsTree(projects)
  {
    // Init the tree with a mutable copy of TQprojects
    this.projectsTree = []
    this.projectsState.TQprojects.forEach(p => this.projectsTree.push({...p, children:null}))
    this.treeSource.data = [...this.buildProjectsTree(this.projectsTree)]

    // Show currrentProject expanded
    let node = this.findProjectNode(this.treeSource.data, this.appState.projectFilterId)
    this.treeControl.collapseAll()
    this.expandNodeAndParent(node)
  }

  buildProjectsTree(projects)
  {
    if (projects == null) return null;

    const idx = projects.reduce( (acc, el, i) =>
      {
        acc[el.id] = i;
        return acc;
      }, {});

    let tree = [];
    projects.forEach( p =>
      {
        if (p.parent_project_id === null)
        {
          tree.push(p);
          return;
        }
        const parent = projects[idx[p.parent_project_id]];
        parent.children = [...(parent.children || []), p];
      });

    return tree;
  }

  expandNodeAndParent(node)
  {
    if (node == null) return;

    this.treeControl.expand(node)
    if (node.parent_project_id != null)
    {
      let parent = this.findProjectNode(this.treeSource.data, node.parent_project_id)
      this.expandNodeAndParent(parent)
    }
  }

  findProjectNode(tree: any, id: number)
  {
    for (let i in tree)
    {
      let p = tree[i]

      //Search in this level
      if (p.id == id)
      {
        return p
      }
      else
      {
        if (Array.isArray(p.children))
        {
          for (let j in p.children)
          {
            let ch = p.children[j]
            let node = this.findProjectNode([ch], id)
            if (node != null) return node
          }
        }
      }
    }
    return null
  }

  setProjectTo(id: number, project:string, expanded: boolean)
  {
    if (this.appState.projectFilterId == id)
    {
      this.store.dispatch(APP_ACTIONS.projectFiltered({ id:0, code:'', color:'', list:[] }))
    }
    else
    {
      let color = this.projectsState.TQprojects
        .find(p =>
          {
            return p.code == project
          }).color
      this.store.dispatch(APP_ACTIONS.projectFiltered({ id:id, code:project, color: color, list:this.getProjectFilterList(id) }))
    }
  }

  getProjectFilterList(id: number)
  {
    if (id == 0)
    {
      return []
    }
    else
    {
      let node = this.findProjectNode(this.treeSource.data, id)
      if (!!node)
      {
        return this.getProjectGroupList(node)
      }
    }
  }

  getProjectGroupList(node: any): Array<number>
  {
    // Add the node Id ...
    let projectsList = [node.id]
    // ... and all children family
    if (node.children != null)
    {
      node.children.forEach( ch =>
        {
          projectsList.push(...this.getProjectGroupList(ch))
        })
    }

    return projectsList
  }

  toggleSidebar()
  {
    if (this.appState.sidebarCollapsed) 
    {
      this.store.dispatch(APP_ACTIONS.sidebarCollapsed({value:false}))
    }
    else
    {
      this.store.dispatch(APP_ACTIONS.sidebarCollapsed({value:true}))
    }
  }

  onProjectMenuCommand(command: string, project: any)
  {
    // Ensure the project is selected
    // if (command != "Select" && this.appState.projectFilterId != project.id) 
    // {
    //   this.setProjectTo(project.id, project.code, this.treeControl.isExpanded(project));
    // }

    switch(command)
    {
      case "Add subproject":
        this.router.navigateByUrl('/loading/', { skipLocationChange: true })
          .then(() => { 
            this.router.navigate(['/project/', 0]);
          })
        break;
        case "Select":
          this.setProjectTo(project.id, project.code, this.treeControl.isExpanded(project));
          break;
        case "Edit":
        this.router.navigateByUrl('/loading/', { skipLocationChange: true })
          .then(() => { 
            this.router.navigate(['/project', project.id]);
          })
        break;
      // case "Duplicate":
      //   this.duplicateTask(task.id);
      //   break;
      case "Log Activity":
        this.router.navigateByUrl('/loading/', { skipLocationChange: true })
          .then(() => { 
            this.router.navigate(['/activity/'], { queryParams: { project: project.id }});
          })
        break;
       }
  }



}
