import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';
import * as APP_ACTIONS from 'src/app/appState/app.actions';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy
{
  faQuestionCircle = faQuestionCircle;
  faSpinner = faSpinner;

  appState: AppState;
  appStateSubs: any;
  
  constructor
  (
    private router: Router,
    private samApp: SamuraiService,
    private store: Store,
    private tqApi: TQApiService,
  ) {}

  ngOnInit()
  {
    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state 
      })

    // Log out after a number of seconds if still on loading page
    setTimeout(() => {
      this.samApp.debug("LOADING SLEEP OFF lastPane: "+this.appState.lastPane)
      this.tqApi.debug("LOADING SLEEP OFF lastPane: "+this.appState.lastPane)
      if (this.appState.activePane != 'profile' && 
         (this.appState.lastPane == 'loading') ) // || this.appState.lastPane == 'signin' || this.appState.lastPane == 'signup'))
      {
        this.router.navigate(['/logout']);
      }
    }, 5*1000);

    // Do nothing, just display the spinner
    this.samApp.trace("LOADING...")   
    this.tqApi.pingLOADING();
  }

  ngOnDestroy()
  {
    this.appStateSubs.unsubscribe();
  }
}
