<div id="projectpadPane" class="samPane">
  <div class="samPaneHeader"
    [ngClass]="{'TQ-header-filtered' : projectpadFilter}">
    <h4 class="samPaneTitle">
      Projects
      <a href="https://docs.taskquark.com/projectpad.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h4>
    <div *ngIf="appState.projectFilterCode!= ''">
      <p class="samPaneSubtitle"
        [ngClass]="'TQ-'+appState.projectFilterColor">
        {{appState.projectFilterCode}}
      </p>
      <button 
        class="samPaneSubtitle badge TQ-clear-button mt-3"
        title="Clear (Esc)"
        (click)="clearProjectFilter()">X
      </button>
    </div>
    <fa-icon *ngIf="projectsState.status!='loaded'"
      [icon]="faSpinner" [spin]="true" [pulse]="true" [size]="'lg'"
      class="samPaneTitle">
    </fa-icon>
    <div class="TQ-filter-area">
      <button class="TQ-filter-button"
        (click)="projectpadFilter=!projectpadFilter; clearProjectpadFilter();">
        <fa-icon [icon]="faFilter" class="fa-lg" title="Filter Projectpad (Ctrl-F)"></fa-icon>
      </button>
      <input id="ProjectpadFilter" type="text" class="pl-1"
        [hidden]="!projectpadFilter"
        [(ngModel)]="this.projectpadFilterRegEx">
      <button class="badge TQ-clear-button ml-1 mr-2"
        title="Clear (Esc)"
        [hidden]="!projectpadFilter"
        (click)="clearProjectpadFilter()">X</button>
    </div>
  </div>

  <div>
    <button *ngIf="projectId > 0"
      class="btn btn-sm btn-info TQ-scroll-top-button"
      (click)="clearToTop()">
      <fa-icon [icon]="faArrowUp" title="Scroll to top"></fa-icon>
    </button>
    <button *ngIf="canAddProject"
        class="btn btn-sm btn-success TQ-add-button"
        title="Add Project"
        (click)="editProject()">
        <fa-icon [icon]="faPlus"></fa-icon>
    </button>
    <button *ngIf="allowProjectImportation && canAddProject"
        class="btn btn-sm btn-success import-button"
        title="Import Project"
        (click)="importProject()">
        <fa-icon [icon]="faCalendarPlus"></fa-icon>
    </button>

    <div *ngIf="!canAddProject"
      class="alert alert-danger" role="alert">
      You have reached the maximum number of projects for your subscription plan.
    </div>

    <p-treeTable 
      [value]="projectpadTree"
      [resizableColumns]="true" 
      cdkDropList #ProjectList="cdkDropList"
      (cdkDropListDropped)="drop($event)"
      class2="table table-bordered table-striped"
      class="TQ-table"
    >
      <ng-template pTemplate="header">
          <tr class="m-0">
            <th ttResizableColumn class="TQ-col-3 m-0 p-1 pl-2">Code</th>
            <th ttResizableColumn class="TQ-col-2 m-0 p-1 pl-2">Role</th>
            <th ttResizableColumn class="TQ-col-79 m-0 p-1 pl-2">Project</th>
          </tr>
      </ng-template>

      <ng-template pTemplate="body" 
        let-rowNode
        let-rowData="rowData"
      >
        <tr id="col1-{{rowData.id}}"
          class="m-0 p-0 project-cursor"
          [class.table-success]="(rowData.id==this.projectId)"
          [class.table-warning]="(rowData.droppable==false)"
          cdkDrag cdkDragLockAxis="y"
          [cdkDragData]="[rowData]"
          [cdkDragStartDelay]="{touch:400,mouse:0}"
          (cdkDragStarted)="dragStarted($event, rowData)"
          (click)="editProject(rowData)">
          <td class="TQ-col-3 m-0 p-0">
            <p-treeTableToggler 
              class="m-0 p-0"
              [rowNode]="rowNode">
            </p-treeTableToggler>
            <span 
              class="badge TQ-badge ml-1 text-break"
              [ngClass]="'TQ-'+rowData.color">
              {{rowData.code}}
            </span>
          </td>
          <td class="TQ-col-2 m-0 p-0 pl-2" 
            style="vertical-align: middle">
            {{rowData.role_code}}          
          </td>
          <td class="TQ-col-7 m-0 p-0 pl-2" 
            style="vertical-align: middle">
            <span class="text-break">
              {{rowData.title}}
            </span>
          </td>
        </tr>
      </ng-template>
  </p-treeTable>

  </div>
</div>
